<template>
  <div class="overview-section">
    <div class="flex">
      <div :class="['space-y-4 md:w-1/2', { stateFull: usergoals }]">
        <div class="xl:flex xl:space-x-4">
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="productTypeLabel"
              >Product Type</label
            >
            <div class="text-base font-bold" data-test="productTypeField">
              <span v-if="[6, 7, 8].includes($store.getters['policy/productNo'])" class="mr-1">
                Excess</span
              >
              <span>{{ propertyDetails.productType }}</span>
            </div>
          </div>
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <div class="flex items-center">
              <label class="text-gray-500 font-bold mr-2 !text-sm" data-test="inceptionDateLabel"
                >Inception Date</label
              >
              <!-- pencil-alt / edit icon -->
              <svg
                v-if="
                  canChangeInceptionDate &&
                    !isEarthquake &&
                    !$readonly &&
                    ($internal || originalTransactionType !== 'RN')
                "
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
                data-test="changeInceptionDateBox"
                @click="$root.$emit('openChangeInceptionDateModal')"
              >
                <path
                  d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                />
                <path
                  fill-rule="evenodd"
                  d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <p class="text-base font-bold mr-2" data-test="inceptionDateField">
              {{ policyData.inceptionDate | dateFormat }}
            </p>
          </div>
        </div>

        <!-- new row -->
        <div v-if="isEarthquake" class="xl:flex xl:space-x-4">
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm">Census Block</label>
            <div class="text-base font-bold" data-test="censusBlockField">
              {{ policyData.censusBlock }}
            </div>
          </div>
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm">Zip Code</label>
            <div class="text-base font-bold" data-test="zipCodeField">
              {{ policyData.zip }}
            </div>
          </div>
        </div>

        <div v-if="!isEarthquake" class="xl:flex xl:space-x-4">
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="propertyTypeLabel"
              >Property Type</label
            >

            <div class="text-base font-bold" data-test="propertyTypeField">
              {{ propertyDetails.propertyTypeDesc || "-" }}
            </div>
          </div>
        </div>

        <div v-if="!isEarthquake" class="xl:flex xl:space-x-4">
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="priorLossLabel"
              >Prior Loss History</label
            >
            <div class="text-base font-bold" data-test="priorLossField">
              {{ propertyDetails.priorLossHistory }}
            </div>
          </div>
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="occupancyTypeLabel"
              >Occupancy Type</label
            >

            <div class="text-base font-bold" data-test="occupancyTypeField">
              {{ occupancyType }}
            </div>
          </div>
        </div>

        <div v-if="!isEarthquake" class="xl:flex xl:space-x-4">
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="foundationTypeLabel"
              >Foundation Type</label
            >

            <div class="text-base font-bold" data-test="foundationTypeField">
              {{ propertyDetails.foundationTypeDesc }}
            </div>
          </div>
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="constructionTypeLabel"
              >Construction Type</label
            >

            <div class="text-base font-bold" data-test="constructionTypeField">
              {{ propertyDetails.constructionDesc }}
            </div>
          </div>
        </div>

        <div v-if="!isEarthquake" class="xl:flex xl:space-x-4">
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="billInitiallyLabel"
              >Bill Initially</label
            >

            <div class="text-base font-bold" data-test="billInitiallyField">
              {{ propertyDetails.billInitially }}
            </div>
          </div>
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="billRenewalLabel"
              >Bill on Renewal</label
            >

            <div class="text-base font-bold" data-test="billRenewalField">
              {{ propertyDetails.billOnRenewal }}
            </div>
          </div>
        </div>

        <div v-if="!isEarthquake" class="xl:flex xl:space-x-4">
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="numStoriesLabel"
              >Number of Stories</label
            >

            <div class="text-base font-bold" data-test="numStoriesField">
              {{ propertyDetails.stories || "-" }}
            </div>
          </div>
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="numUnitsLabel"
              >Number of Units</label
            >

            <div class="text-base font-bold" data-test="numUnitsField">
              {{ propertyDetails.numberOfUnits || "-" }}
            </div>
          </div>
        </div>

        <div v-if="!isEarthquake" class="xl:flex xl:space-x-4">
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <label class="text-gray-500 font-bold !text-sm" data-test="floodZoneLabel"
              >Flood Zone</label
            >

            <div class="text-base font-bold" data-test="floodZoneField">
              {{ propertyDetails.floodZone || "-" }}
            </div>
          </div>
          <div class="mb-4 xl:mb-0 xl:w-1/2">
            <div>
              <label class="text-gray-500 font-bold !text-sm mr-2" data-test="ecElevLabel"
                >EC Elevation</label
              >
              <div
                v-if="$internal && showReview"
                class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20"
              >
                Requires Review
              </div>
            </div>

            <div class="text-base font-bold" data-test="ecElevField">
              {{ propertyDetails.ecElevation || "-" }}
            </div>
          </div>
        </div>
      </div>
      <div class="md:w-1/2 px-6">
        <StateCountyViewer
          class="h-[300px]"
          :counties="[latestTransaction.county]"
          :state="latestTransaction.state"
        />
      </div>
    </div>
    <!-- Edit Inception Date Modal -->

    <el-dialog
      :visible.sync="showEditInceptionDateDialog"
      title="Change Inception Date"
      width="40%"
      append-to-body
      custom-class="dialog-class"
      data-test="idcTitle"
    >
      <div v-if="loading" key="preloader" class="content-loading">
        <lottie-viewer path="/static/lottie/preloader3.json" :loop="true" />
      </div>
      <div v-else>
        <el-date-picker
          v-model="modifiedInceptionDate"
          style="width: 100%;"
          class="mb-2"
          :clearable="false"
          format="MM/dd/yyyy"
          :picker-options="pickerOptions"
          :disabled="modalDisabled"
          data-test="idcDatePicker"
        />
        <span class="text-gray-500 font-sm" data-test="idcDateNote">
          <span class="font-bold">Note:</span>
          Changing the inception date will cancel this policy and rewrite a new policy.
        </span>
        <!-- aor change -->
        <div v-if="$internal" class="mt-5">
          <el-input
            v-model="newAor"
            :disabled="disableAORBox"
            :value="newAor"
            placeholder="New Agent Number"
            data-test="idcNewAgentNumber"
          />
          <p class="text-gray-500 font-sm mt-2">
            <span class="font-bold" data-test="idcAgencyNote">Note: </span> Enter new agency number
            to change the agent of record, otherwise this box may be left blank to retain the same
            agency number.
          </p>
        </div>
        <span
          v-if="modifyInceptionDateError"
          class="mt-2 block text-red-600 font-sm"
          data-test="idcDateError"
        >
          {{ modifyInceptionDateError }}
        </span>
        <span
          v-else-if="modifyInceptionDateNewPolicyNo"
          class="mt-2 block text-green-600 font-sm"
          data-test="idcSuccessMessage"
        >
          Policy <span class="font-bold">{{ policyNo }}</span> has been cancelled. The new policy
          number is
          <a
            :href="`#${$route.fullPath.replace(policyNo, modifyInceptionDateNewPolicyNo)}`"
            target="__new"
          >
            {{ modifyInceptionDateNewPolicyNo }}
          </a>
          .
          <span v-if="newAor !== ''">The new agent of record is {{ newAor }}.</span>
          Please click the "View New Policy" button below to navigate to the new policy.
        </span>
      </div>

      <div slot="footer" class="flex justify-between">
        <div class="flex mt-1">
          <p class="text-gray-500 font-sm" data-test="idcImmediateClosingTitle">
            Immediate Closing
          </p>
          <el-switch
            v-model="hasImmediateClosing"
            :disabled="inceptionDateGreaterThanToday || modalDisabled"
            class="mt-1 ml-3"
            data-test="idcImmediateClosingToggle"
          />
        </div>
        <div>
          <button
            class="inline-flex items-center mr-2 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium"
            data-test="idcExitButton"
            @click="cancelModifyInceptionDate()"
          >
            Exit
          </button>
          <el-button
            v-if="!modifyInceptionDateNewPolicyNo"
            :disabled="isModifyingInceptionDate"
            class="!bg-deepsea-full !text-white hover:!bg-deepsea-full/75"
            data-test="idcUpdateButton"
            @click="updateInceptionDate()"
          >
            Update
          </el-button>
          <a
            v-else
            :href="`#${$route.fullPath.replace(policyNo, modifyInceptionDateNewPolicyNo)}`"
            target="_blank"
            class="ml-3 mt-5 view-new"
            data-test="idcViewNewPolicy"
          >
            View New Policy
          </a>
        </div>
      </div>
      <p
        v-if="hasImmediateClosing"
        class="text-sm mt-4 p-3 bg-blue-50 text-blue-900 border-blue-300 border rounded"
      >
        The standard 10-day waiting period can only be waived for properties involved in a real
        estate closing and/or when providing roll-over coverage from another flood insurance policy.
        Documentation must be provided in the form of a closing disclosure or settlement statement
        showing the exact date of closing matching the effective date, or a copy of the declaration
        page of other coverage showing an expiration date matching the effective date for
        roll-overs. Documents must be uploaded to the policy management system.
      </p>
    </el-dialog>
  </div>
</template>

<script>
import startCase from "lodash/startCase";
import { mapState } from "vuex";
import StateCountyViewer from "../../organisms/geo/StateCountyViewer.vue";
import words from "lodash/words";
import { getProductType, getProductTypeInfo } from "@/product-types";
import { formatDate } from "@/utils/TextFormatting";
import { Constants } from "../../../config";
import { authAxios } from "../../../store/modules/auth";
import LottieViewer from "@/components/policy-wizard/components/LottieViewer.vue";

export default {
  filters: {
    /** @type {(date: number | string) => string} */
    dateFormat: (value) => {
      if (value.endsWith("Z")) {
        const noTimezoneValue = value.substring(0, value.length - 1);
        return formatDate(noTimezoneValue);
      }
      return formatDate(value);
    },
    /**
     * @type {(str: string) => string}
     */
    words(str) {
      return startCase(words(str).join(" "));
    }
  },
  components: {
    StateCountyViewer,
    LottieViewer
  },
  props: {
    usergoals: {
      type: Boolean
    },
    policyNo: {
      type: String
    }
  },
  data() {
    return {
      newAor: "",
      loading: false,
      isModifyingInceptionDate: false,
      showEditInceptionDateDialog: false,
      changingInceptionDate: false,
      modifiedInceptionDate: null,
      modifyInceptionDateError: null,
      modifyInceptionDateNewPolicyNo: null,
      disableAORBox: false,
      cancelDatesObj: {},
      hasImmediateClosing: false,
      modalDisabled: false
    };
  },
  computed: {
    ...mapState("quoting", {
      /** @type {neptune.policyholder.IPolicyTransactionItem} */
      transactions: "transactions"
    }),
    showReview() {
      return this.$store.state?.document?.ocrDocs?.length;
    },
    originalTransactionType() {
      return this.$store.state?.policy?.transactionSummary?.originalTransType;
    },
    isEarthquake() {
      return (
        this.$store.getters["policy/productNo"] === 4 ||
        this.$store.getters["policy/productNo"] === 5
      );
    },
    pickerOptions() {
      return {
        disabledDate: (date) => {
          const max = new Date(this.cancelDatesObj.maxEffectiveDate);
          let min = new Date();
          if (this.hasImmediateClosing) {
            min = new Date(this.cancelDatesObj.minEffectiveDate_Immediate);
          } else {
            min = new Date(this.cancelDatesObj.minEffectiveDate_NotImmediate);
          }
          return !(date >= min && date <= max);
        }
      };
    },

    inceptionDateGreaterThanToday() {
      const newDate = new Date(
        new Date(this.$store.getters["policy/allData"].inceptionDate).toLocaleString().slice(0, 10)
      );
      const today = new Date(new Date().toLocaleDateString());
      return newDate <= today;
    },

    /** @type {() => neptune.IPolicyData} */
    policyData() {
      return this.$store.getters["policy/allData"];
    },

    /** @type {() => Vue.ComputedOptions<neptune.policyholder.IPolicyTransactionItem>} */
    latestTransaction() {
      /** @type {neptune.policyholder.IPolicyTransactionItem[]} */
      const items = this.transactions;

      return items[items.length - 1];
    },

    /**
     * Extracted Details
     */
    propertyDetails() {
      const {
        foundationTypeDesc,
        stories,
        floodZone,
        propertyTypeDesc,
        productId: productNo,
        billInitially,
        meta,
        ecElevation,
        constructionDesc,
        billOnRenewal,
        priorClaims,
        occupancyTypeDesc
      } = this.latestTransaction || {};

      const propertyType = propertyTypeDesc.toLowerCase();
      const { numberOfUnits, mixedUseResidentialPct } = JSON.parse(meta);

      const productTypeName = getProductType({
        propertyType,
        numberOfUnits,
        productNo,
        mixedUseResidentialPct: mixedUseResidentialPct ?? 0
      });
      const info = getProductTypeInfo(productTypeName);

      const foundationMap = {
        slab: "Slab",
        crawlspaceElevated: "Elevated crawlspace",
        crawlspaceSubgrade: "Subgrade crawlspace",
        basement: "Basement",
        elevatedOnPostPilesPiers: "Elevated without enclosure on posts, piles, or piers",
        elevatedWithEnclosureOnPostPilesPiers: "Elevated with enclosure on posts, piles, or piers",
        elevatedWithEnclosure: "Elevated with enclosure not on posts, piles, or piers"
      };

      const ret = {
        foundationTypeDesc: foundationMap[foundationTypeDesc] || startCase(foundationTypeDesc),
        stories: this.storiesToFriendlyName(stories, productNo, occupancyTypeDesc),
        floodZone,
        billInitially,
        billOnRenewal,
        propertyTypeDesc,
        priorLossHistory: !priorClaims || priorClaims === "NOPE" ? "No" : "Yes",
        constructionDesc,
        ecElevation,
        numberOfUnits,
        productType: info?.title ?? "Unknown",
        occupancyTypeDesc
      };

      if (!numberOfUnits) {
        delete ret.numberOfUnits;
      }

      return ret;
    },

    occupancyType() {
      if (this.propertyDetails.occupancyTypeDesc === "rcbap") return "RCBAP";
      return this.propertyDetails.occupancyTypeDesc.replace(/([a-z](?=[A-Z]))/g, "$1 ");
    },

    /**
     * AGENT = 1, INSURED = 2
     * @type {() => Vue.ComputedOptions<number>}
     */
    userType() {
      return this.$route.meta?.userType || 1;
    },
    agent() {
      return this.userType === 1;
    },

    /** @type {() => {agent: boolean, insured: boolean, currentUser: boolean}} */
    signed: {
      get() {
        const isSigned = this.policyData.isSigned || [];

        const agent = isSigned.some((i) => i.isSigned && i.party === 1);
        const insured = isSigned.some((i) => i.isSigned && i.party === 2);

        return {
          agent,
          insured,
          currentUser: this.agent ? agent : insured
        };
      },

      set(bool) {
        /** @type {neptune.policyholder.IPolicySummaryResponse} */
        const { isSigned } = this.$store.state.policy.confirmationData;

        /** @type {neptune.policyholder.IsSigned[]} */
        const cloned = JSON.parse(JSON.stringify(isSigned));
        const matched = cloned.find((v) => v.party === this.userType);
        matched.isSigned = bool;

        this.$store.commit("policy/SET_PROP", {
          path: "confirmationData.isSigned",
          value: cloned
        });
      }
    },

    paid: {
      get() {
        return this.policyData.isPaid;
      },

      set(bool) {
        this.$store.commit("policy/SET_PROP", {
          path: "confirmationData.isPaid",
          value: bool
        });
      }
    },

    paidBy() {
      if (!this.paid) {
        return;
      }
      const invoices = this.policyData.invoices.invoices;

      const byType = invoices[0]?.invoicePayments[0]?.createdAtUserType;

      return byType === 1 ? "Agent" : "Insured";
    },

    paymentMethod() {
      return this.policyData.paymentMethod;
    },

    statusObjects() {
      const {
        signed: { agent: agentSigned, insured: insuredSigned },
        paid,
        paidBy
      } = this;

      return {
        agentSigned,
        insuredSigned,
        paid,
        paidBy
      };
    },

    needs() {
      return {
        toSign: !this.signed.agent || !this.signed.insured,
        toPay: !this.paid
      };
    },

    actionNeeded() {
      const todo = Object.entries(this.needs)
        .filter((v) => !!v[1])
        .map((v) => v[0]);

      return todo;
    },
    policyCancelled() {
      const { policy } = this.$store.state;
      return policy?.confirmationData?.isPolicyCancelled;
    },
    pendingCancel() {
      return this.policyData.isPendingCancel;
    },
    cancelled() {
      return this.policyCancelled && !this.pendingCancel;
    },
    canChangeInceptionDate() {
      return this.$store?.state?.policy?.confirmationData?.uiConfiguration?.canChangeEffectiveDate;
    }
  },
  mounted() {
    this.hasImmediateClosing = this.$store?.getters["policy/allData"]?.hasImmediateClosing;
  },

  methods: {
    storiesToFriendlyName(stories, productNo, occupancy) {
      switch (productNo) {
        case 1:
        case 6:
          if (occupancy !== "Condo Which Floor" && stories === 3) {
            return "3 or more";
          } else if (occupancy === "Condo Which Floor" && stories === 4) {
            return "4 or more";
          } else {
            return stories;
          }
        case 2:
        case 3:
        case 7:
        case 8:
          if (occupancy !== "CommercialTenant" && stories === 100) {
            return "100 or more";
          } else if (occupancy === "CommercialTenant" && stories === 4) {
            return "4 or more";
          } else if (occupancy === "CommercialOwnerSingleTenant" && stories === 3) {
            return "3 or more";
          } else {
            return stories;
          }
        default:
          return stories;
      }
    },
    reloadWindow() {
      window.location.reload();
    },
    cancelModifyInceptionDate() {
      this.modifiedInceptionDate = null;
      this.showEditInceptionDateDialog = false;
    },
    async modifyInceptionDate() {
      this.modifiedInceptionDate = this.policyData.inceptionDate;
      this.showEditInceptionDateDialog = true;
      try {
        this.loading = true;
        const res = await authAxios.get(
          Constants.GET_CANCEL_REWRITE_DATES.replace("{{policyNo}}", this.policyNo)
        );
        this.cancelDatesObj = res.data.payload;
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
    async updateInceptionDate() {
      const url = Constants.CANCEL_REWRITE_URL.replace("{{policyNo}}", this.policyNo);
      this.loading = true;
      try {
        this.modifyInceptionDateError = null;
        this.modifyInceptionDateNewPolicyNo = null;
        this.isModifyingInceptionDate = true;

        const { data } = await authAxios.post(url, {
          inceptionDate: this.modifiedInceptionDate,
          newAgencyNo: this.newAor,
          hasImmediateClosing: this.hasImmediateClosing === null ? false : this.hasImmediateClosing
        });
        if (data.statusCode !== 200) {
          throw new Error(data.message);
        }
        this.modalDisabled = true;
        this.modifyInceptionDateNewPolicyNo = data.payload.policy.policyNo;
        this.$emit("successfulInceptionChange");
        this.disableAORBox = true;
        this.loading = false;
      } catch (e) {
        const message = e.response?.data?.message ?? e.message;
        this.modifyInceptionDateError = message;
        this.loading = false;
      } finally {
        this.isModifyingInceptionDate = false;
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.view-new {
  background-color: #26a97b;
  color: #fff;
  width: 100%;
  padding: 9px 10px 11px 10px;
  border-radius: 4px;
  font: inherit;
}
.stateFull {
  margin-right: 120px;
}
.content-loading {
  margin-top: -120px;
  margin-bottom: -100px;
}
::v-deep .dialog-class {
  margin-bottom: auto;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.colMargin {
  margin-top: 1.5rem;
}
.titleColor {
  color: rgb(103, 156, 187);
}
</style>
